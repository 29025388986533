import { useContext, useEffect, useRef } from 'react';
import { LanguageContext } from "../../context/LanguageContext"; // 引入LanguageContext
import api from '../../api/axiosConfig';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ReviewForm from '../reviewForm/ReviewForm';

import React from 'react'

const Reviews = ({ getMovieData, movie, reviews, setReviews }) => {
	const { texts } = useContext(LanguageContext); // 使用LanguageContext
	const revText = useRef();
	let params = useParams();
	const movieId = params.movieId;

	useEffect(() => {
		getMovieData(movieId);
	}, [])

	const addReview = async (e) => {
		e.preventDefault();

		const rev = revText.current;

		try {
			const response = await api.post("/api/v1/reviews", { reviewBody: rev.value, imdbId: movieId });

			const updatedReviews = [...reviews, { body: rev.value }];

			rev.value = "";

			setReviews(updatedReviews);
		}
		catch (err) {
			console.error(err);
		}
	}

	return (
		<Container>
			<Row>
				<Col><h3>{texts.reviews}</h3></Col>
			</Row>
			<Row className="mt-2">
				<Col>
					<img src={movie?.poster} alt="" />
				</Col>
				<Col>
					<>
						<Row>
							<Col>
								<ReviewForm handleSubmit={addReview} revText={revText} labelText={texts.writeReview} />
							</Col>
						</Row>
						<Row>
							<Col>
								<hr />
							</Col>
						</Row>
					</>
					{
						reviews?.map((r, index) => {
							return (
								<React.Fragment key={index}>
									<Row>
										<Col>{r.body}</Col>
									</Row>
									<Row>
										<Col>
											<hr />
										</Col>
									</Row>
								</React.Fragment>
							)
						})
					}
				</Col>
			</Row>
			<Row>
				<Col>
					<hr />
				</Col>
			</Row>
		</Container>
	)
}

export default Reviews;
